.navbar {
    background-color: #1a2a4f;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar-logo {
    height: auto;
    max-width: 10%;
    margin-right: 10px;
}

.navbar-brand {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.navbar-toggler {
    margin-right: auto;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #1a2a4f;
}

@media (min-width: 992px) {
    .navbar-collapse {
        position: static;
        background-color: transparent;
    }
}

